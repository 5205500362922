import gql from "graphql-tag";

export const surveyFragment = gql`
  {
    id
    targetCustomerUser {
      id
      name
      targetCustomer {
        id
        tags {
          id
          name
          type
          category
          confirmationQuestion
        }
        name
        type
        status
        estMarketSize
        estAnnualIncome
      }
      venture {
        id
      }
    }
    surveyOpportunities {
      id
      headlineDefault
      sortOrder
      responses {
        id
        user {
          id
          firstName
          lastName
        }
        isArchived
      }
      opportunity {
        id
        name
        avgFrequency
        frequencyUnit
        frequency
        problems {
          id
          name
          economy {
            id
            name
            problem
          }
        }
      }
      surveyQuestionGroup {
        id
        questions {
          id
          category
          inputType
          question
          subcategory
          sortOrder
          answerOptions {
            id
            answer
            metadata
          }
          helpBlurb
        }
      }
    }
    welcomeHeading
    welcomeDescription
    closingHeading
    closingDescription
    publicUrl
  }
`;
