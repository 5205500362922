import gql from "graphql-tag";
import { companyFragment } from "@/store/effects/gql/company/fragments";
import { cohortFragment } from "../cohort/fragments";

export const PaymentMethod = `
{
  id type expirationDate cardType last4 isValid isDefault createdAt methodType isValid image
  vendors { name token callbackUrl redirectUrl isValid checkoutUrl }
}
`;
/*
 *
 */
export const userFragment = gql`{
 id
  balance
  bio
  appVersion
  needToUpgrade
  timezoneOffset
  chatId
  playerId
  gender
  timezone
  firstName
  middleName
  username
  lastName
  fullName
  dateOfBirth
  phones{id number}
  cohorts${cohortFragment}
  company${companyFragment}
  ventures {
    id name milestones{id name} targetCustomerUsers{id} opportunities{id} users{id}
  }
  userGroups{
    id
    group{id name type}
    subgroups{id group{id name}}
    createdAt
    updatedAt
  }
  companies{id name description ventures{id} programs{id cohorts{id}} users { id firstName lastName fullName groups { id name } } createdAt}
  email
  emails{id address verified}
  race { id }
  tags{id name type category}
  avatar
  createdAt
  updatedAt
  site { id name address address2 city state postalCode country phones{id number} googlePlacesId gps{lat lon} }
  sites { id name address address2 city state postalCode country phones{id number} googlePlacesId gps{lat lon}}
  paymentMethods${PaymentMethod}
  groups { id name }
  socialHandles { id platform handle }
  transactions { id paymentMethodCardId amountPaid status note type createdAt }
  contactRequests { id name }
  opportunities { id isArchived}
  metadata
}`;

/*
 *
 */
export const AuthPayLoad = gql`
{
  user${userFragment}
  verificationCode
  isNewUser
}
`;
