import gql from 'graphql-tag';
import { userFragment, AuthPayLoad } from './fragments';

/*
*
*/
export const saveUser = gql`
  mutation saveUser($data: UserUpdateInput!, $where: UserWhereUniqueInput) {
    saveUser(data: $data, where: $where) ${userFragment}
  }
`;

/*
*
*/
export const deleteUser = gql`
  mutation deleteUser($where: UserWhereUniqueInput) {
    deleteUser(where: $where) ${userFragment}
  }
`;

/*
*
*/
export const createAppUser = gql`
  mutation createAppUser($mobileNumber: String $email: String $password: String $generateToken: Boolean $disableSendingSmsCode: Boolean, $appVersion: String $sendEmailVerification: Boolean $userType: String) {
    createAppUser(generateToken: $generateToken email: $email password: $password mobileNumber: $mobileNumber disableSendingSmsCode: $disableSendingSmsCode appVersion: $appVersion sendEmailVerification: $sendEmailVerification userType: $userType) ${AuthPayLoad}
  }
`;

/*
*
*/
export const verifySmsCode = gql`
  mutation verifySmsCode($userId: String! $verifyCode: String! $mobileNumber: String $device: Json) {
    verifySmsCode(userId: $userId verifyCode: $verifyCode mobileNumber: $mobileNumber device: $device) {
      user {
        id
      }
      token
    }
  }
`;

/*
*
*/
export const login = gql`
  mutation login($email:String! $password:String!) {
    login(email:$email password:$password) {
      token
      user ${userFragment}
    }
  }
`;

/*
*
*/
export const loginWithToken = gql`
  mutation loginWithToken($token:String! $userId:String! $appVersion:String) {
    loginWithToken(token:$token userId:$userId appVersion:$appVersion) {
      user ${userFragment}
    }
  }
`;

/*
*
*/
export const triggerPasswordReset = gql`
  mutation triggerPasswordReset($email:String! $domain:String!) {
    triggerPasswordReset(email:$email domain:$domain)
  }
`;

/*
*
*/
export const passwordReset = gql`
  mutation passwordReset($email:String! $resetToken:String! $password:String!) {
    passwordReset(email:$email resetToken:$resetToken password:$password)
  }
`;

export const resendVerificationEmail = gql`
  mutation resendVerificationEmail($userId: String! $email: String! $userType: String) {
    resendVerificationEmail(userId: $userId email: $email userType: $userType)
  }
`

/*
*
*/
export const requestSupport = gql`
  mutation requestSupport($userId: String $subject: String! $message: String! $email: String $phone: String $reason: String $companies: [String!]) {
    requestSupport(userId: $userId subject: $subject message: $message email: $email phone: $phone reason: $reason companies: $companies)
  }
`;

export const updateUserProfile = gql `
  mutation updateUser(
    $userId: String! 
    $email: String 
    $avatar: String 
    $gender: String
    $fullName: String
    $firstName: String 
    $lastName: String 
    $paymentMethod: PaymentMethodInput 
    $removePaymentMethods: [String] 
    $removeEmails: [String] 
    $removePhones: [String]
    $lunchtime: DateTime 
    $googlePlacesId: String 
    $gps: GeoLocCreateInput 
    $timezoneOffset: Int 
    $timezone: String $address2: String
    $addressNumber: String
    $addressName: String
    $siteId: String
    ) {
    updateUser(userId: $userId, email: $email avatar:$avatar fullName:$fullName firstName:$firstName lastName:$lastName
      paymentMethod:$paymentMethod removePaymentMethods:$removePaymentMethods removeEmails:$removeEmails removePhones:$removePhones lunchtime:$lunchtime gender: $gender
      googlePlacesId:$googlePlacesId gps:$gps timezoneOffset:$timezoneOffset timezone:$timezone address2: $address2
      addressNumber: $addressNumber
      addressName: $addressName
      siteId: $siteId
    ) ${AuthPayLoad}
  }
`;

export const deactivateUser = gql`
 mutation deactivateUser(
    $userId: String!
  ) {
    deactivateUser(
      userId: $userId
    )
  }
  `

export const validateFluteCode = gql`
  mutation validateFluteCode(
    $promoCode: String!,
    $userId: String!,
    $image: String,
    $locationPlaceId: String
  ) {
    validateFluteCode(
      promoCode: $promoCode,
      userId: $userId,
      image: $image,
      locationPlaceId: $locationPlaceId
    )
  }
  `

export const sendFlutes = gql`
  mutation sendFlutes(
    $fromUserId: String!
    $toFlutes: [ToFlute!]
    $useWallet: Boolean
  ) {
    sendFlutes(
      toFlutes: $toFlutes,
      fromUserId: $fromUserId,
      useWallet: $useWallet
    )
  }
`

export const doAccountsExist = gql`
   mutation doAccountsExist(
    $mobileNumbers: [String]
  ) {
    doAccountsExist(
      mobileNumbers: $mobileNumbers
    ) {
      id avatar username phones{ number verified} status
    }
  }
`

export const inviteContact = gql`
  mutation inviteContact(
     $contacts: [InviteContactCreateInput!]
     $userId: String!
     $extraData: Json
  ) {
    inviteContact(
      contacts: $contacts
      userId: $userId
      extraData: $extraData
    )
  }
  `;

export const deleteUserAddress = gql`
    mutation deleteUserAddress($siteId: String! $userId: String!){
        deleteUserAddress(siteId: $siteId, userId: $userId)${userFragment}
    }
`

export const verifyEmail = gql`
  mutation verifyEmail($email: String! $token: String!) {
    verifyEmail(email: $email token: $token)
  }
`

export const uploadFileToS3 = gql`
  mutation uploadFileToS3($image: String! $path: String $stream: Boolean) {
    uploadFileToS3(image: $image path: $path stream: $stream)
  }
`

export const sendUsersInvitation = gql`
  mutation sendUsersInvitation($invites: [VentureInviteUser] $userId: String!) {
    sendUsersInvitation(invites: $invites, userId: $userId)
  }
`

export const addUserToEcosystem = gql`
  mutation addUserToEcosystem($userId: String!, $ecosystemId: String!) {
    addUserToEcosystem(userId: $userId ecosystemId: $ecosystemId) {id}
  }
`;

export const addUserToCohort = gql`
  mutation addUserToCohort($userId: String!, $cohortId: String!) {
    addUserToCohort(userId: $userId cohortId: $cohortId){id}
  }
`;

export const addAdminUserToEcoSystem = gql`
  mutation addAdminUserToEcoSystem($userId: String!, $ecosystemId: String!) {
    addAdminUserToEcoSystem(userId: $userId, ecosystemId: $ecosystemId) {
      id
    }
  }
`;

