import gql from "graphql-tag";
import { userFragment } from "@/store/effects/gql/user/fragments";
import { companyFragment } from "@/store/effects/gql/company/fragments";
import { cohortFragment } from "@/store/effects/gql/cohort/fragments";

export const ventureFragment = gql`{
    id
		name
    mode
    description
     blurb
    status
    hypotheses {id name}
    milestones {id name message modalMessage tagLabel sortOrder video{id type source avatar} resources{id name type}}
    createdBy {id email username}
    targetCustomerUsers {
      id name notes isArchived
      interviews { id name isArchived scheduledDateTime opportunities { id name isArchived }}
      contacts {id isArchived  user{id firstName socialHandles{handle platform} lastName email emails {id address}} status relationship interviews{id name scheduledDateTime opportunities{id} contact{id user{id firstName lastName}}} targetCustomerUser{id targetCustomer{name}}}
      opportunities{
        id
        type
        name
        isArchived
        desireScore
        avgFrequency
        frequencyUnit
        frequency
        magnitudeScore
        adjustedMarket
        outOfMarketPercentage lowMarketPercentage midMarketPercentage hiMarketPercentage
        actionDonePercentage
        isArchived
        actionStep {
          id
          name
        }
        parentOpportunityLinks {
          id
          child{id}
          parent {
            id
            name
          }
        }
        childOpportunityLinks {
          id
          parent{id}
          child {
            id
            name
          }
        }
        experience
        isActionDone
        interviews {id isArchived}
        problems {
          id
          name
          economy {id name problem}
        } }
      targetCustomer {
        id tags { id name type category confirmationQuestion } name type status estMarketSize estAnnualIncome 
      }
      researchPrompt
      estMarketSize estAnnualIncome
      employeeSize 
      researchSource
      emailTemplates{id name type version subject body metadata isArchived}
      interviewScripts{id introScript questionScript outroScript name}
      surveys { id name userSurveys { id isCompleted isArchived profile {id status} user{id firstName lastName fullName} } surveyOpportunities{id sortOrder opportunity{id name} responses{id isArchived profile {id status} user{id firstName lastName fullName}} 
      surveyQuestionGroup{id questions{id category inputType question subcategory sortOrder answerOptions{id answer metadata}}}} welcomeHeading welcomeDescription closingHeading closingDescription publicUrl}
    }
    metrics{id name}
    interviews {id name isArchived}
    contacts{
      id isArchived user{id firstName lastName email emails {id address} socialHandles{platform handle}} createdAt opportunities {id isArchived} relationship targetCustomerUser{id name} status interviews{id scheduledDateTime}
    }
    opportunities{id isArchived name source frequency avgFrequency frequencyUnit actionDonePercentage desireText outOfMarketPercentage lowMarketPercentage midMarketPercentage hiMarketPercentage experience magnitudeScore desireScore adjustedMarket type feeling parentOpportunityLinks{id} problems{id economy{id name}} surveyOpportunities{id responses{id isArchived}} childOpportunityLinks{id} interviews{id} targetCustomerUser{id name} actionStep{id name} }
    mode
    createdAt
    updatedAt
    users${userFragment}
    resources {
      id
      name
      type
      sortOrder
      milestone{id}
      directLink{id}
      externalLink{id}
      files{id}
      summary
      body  
    }
    isArchived
    createdBy${userFragment}
  company${companyFragment}
  cohorts${cohortFragment}
}`;

// Home, Ventures pages
export const ventureListFragment = gql`
  {
    id
    name
    description
    milestones {
      id
      sortOrder
      tagLabel
    }
    targetCustomerUsers {
      id
      isArchived
    }
    opportunities {
      id
      isArchived
    }
    users {
      id
    }
  }
`;

// Venture Detail page
export const ventureDetailFragment = gql`
  {
    id
    name
    description
    milestones {
      id
      sortOrder
      tagLabel
    }
    targetCustomerUsers {
      id
      name
      opportunities {
        id
        isArchived
      }
      targetCustomer {
        id
      }
      isArchived
      contacts {
        id
        isArchived
        status
        interviews {
          id
          scheduledDateTime
        }
      }
    }
    opportunities {
      id
      isArchived
      name
      targetCustomerUser {
        id
        name
      }
      surveyOpportunities {
        id
        responses {
          id
          isArchived
        }
      }
      outOfMarketPercentage
      lowMarketPercentage
      midMarketPercentage
      hiMarketPercentage
      magnitudeScore
      adjustedMarket
    }
    contacts {
      id
      isArchived
      interviews {
        id
        scheduledDateTime
      }
    }
    interviews {
      id
      isArchived
    }
    createdBy {
      id
    }
    users {
      id
      firstName
      lastName
      groups {
        id
        name
      }
    }
  }
`;

// Venture Contacts page
export const ventureContactFragment = gql`
  {
    id
    targetCustomerUsers {
      id
      name
      isArchived
    }
    contacts {
      id
      isArchived
      user {
        id
        firstName
        lastName
        emails {
          id
          address
        }
        socialHandles {
          id
          handle
          platform
        }
        username
      }
      targetCustomerUser {
        id
        name
      }
      relationship
      status
      interviews {
        id
        scheduledDateTime
      }
    }
  }
`;

// Venture Interviews page
export const ventureInterviewFragment = gql`
  {
    id
    targetCustomerUsers {
      id
      isArchived
      name
    }
    contacts {
      id
      isArchived
      interviews {
        id
        scheduledDateTime
      }
      user {
        id
        firstName
        lastName
        email
      }
      targetCustomerUser {
        id
        name
      }
      relationship
      status
    }
  }
`;

// Venture Opportunities page
export const ventureOpportunityFragment = gql`
  {
    id
    targetCustomerUsers {
      id
      name
      isArchived
    }
    opportunities {
      id
      isArchived
      interviews {
        id
        isArchived
      }
      targetCustomerUser {
        id
        name
      }
      childOpportunityLinks {
        id
      }
      parentOpportunityLinks {
        id
      }
      outOfMarketPercentage
      lowMarketPercentage
      midMarketPercentage
      hiMarketPercentage
      magnitudeScore
      actionDonePercentage
      avgFrequency
      frequencyUnit
      desireScore
      experience
      adjustedMarket
      name
      type
    }
  }
`;

// Venture Settings page
export const ventureSettingFragment = gql`
  {
    id
    name
    description
    company {
      id
      name
      createdAt
    }
    cohorts {
      id
      name
      program {
        id
        name
      }
    }
    mode
    users {
      id
      firstName
      lastName
      groups {
        id
        name
      }
    }
    createdBy {
      id
    }
  }
`;

// Venture Survey Data page
export const ventureSurveyDataFragment = gql`
  {
    id
    targetCustomerUsers {
      id
      name
      isArchived
    }
  }
`;

// Venture Target Customers page
export const ventureTargetCustomerFragment = gql`
  {
    id
    targetCustomerUsers {
      id
      isArchived
      name
      targetCustomer {
        id
        type
      }
      opportunities {
        id
        isArchived
      }
      contacts {
        id
        isArchived
        interviews {
          id
        }
      }
      surveys {
        id
      }
    }
  }
`;

// Venture Contact Detail page
export const ventureContactDetailFragment = gql`
  {
    id
    targetCustomerUsers {
      id
      name
      isArchived
    }
  }
`;

// Venture Target Customer Detail page
export const ventureTargetCustomerDetailFragment = gql`
  {
    id
    createdBy {
      id
      email
    }
    targetCustomerUsers {
      id
      name
      notes
      isArchived
      interviews {
        id
        name
        isArchived
        scheduledDateTime
        opportunities {
          id
          name
          isArchived
        }
      }
      contacts {
        id
        isArchived
        user {
          id
          firstName
          socialHandles {
            handle
            platform
          }
          lastName
          email
          emails {
            id
            address
          }
        }
        status
        relationship
        interviews {
          id
          name
          scheduledDateTime
          opportunities {
            id
          }
          contact {
            id
            user {
              id
              firstName
              lastName
            }
          }
        }
        targetCustomerUser {
          id
          targetCustomer {
            name
          }
        }
      }
      opportunities {
        id
        type
        name
        isArchived
        desireScore
        avgFrequency
        frequencyUnit
        frequency
        magnitudeScore
        adjustedMarket
        outOfMarketPercentage
        lowMarketPercentage
        midMarketPercentage
        hiMarketPercentage
        actionDonePercentage
        isArchived
        actionStep {
          id
          name
        }
        parentOpportunityLinks {
          id
          child {
            id
          }
          parent {
            id
            name
          }
        }
        childOpportunityLinks {
          id
          parent {
            id
          }
          child {
            id
            name
          }
        }
        experience
        isActionDone
        interviews {
          id
          isArchived
        }
        problems {
          id
          name
          economy {
            id
            name
            problem
          }
        }
      }
      targetCustomer {
        id
        tags {
          id
          name
          type
          category
          confirmationQuestion
        }
        name
        type
        status
        estMarketSize
        estAnnualIncome
      }
      researchPrompt
      estMarketSize
      estAnnualIncome
      employeeSize
      researchSource
      emailTemplates {
        id
        name
        type
        version
        subject
        body
        metadata
        isArchived
      }
      interviewScripts {
        id
        introScript
        questionScript
        outroScript
        name
      }
      surveys {
        id
        name
        userSurveys {
          id
          isCompleted
          isArchived
          profile {
            id
            status
          }
          user {
            id
            firstName
            lastName
            fullName
          }
        }
        surveyOpportunities {
          id
          sortOrder
          opportunity {
            id
            name
          }
          responses {
            id
            isArchived
            profile {
              id
              status
            }
            user {
              id
              firstName
              lastName
              fullName
            }
          }
          surveyQuestionGroup {
            id
            questions {
              id
              category
              inputType
              question
              subcategory
              sortOrder
              answerOptions {
                id
                answer
                metadata
              }
            }
          }
        }
        welcomeHeading
        welcomeDescription
        closingHeading
        closingDescription
        publicUrl
      }
    }
  }
`;
